import { securedWrap } from '@mop/shared/utils/securedWrap';
import { escapeStoryblokUrl } from '@mop/cms/utils/utils';
import type { CmsStorySeoData } from '@/types/cms';

export default function useMopSearch() {
  const { getCmsStoryList, cmsStoryListModelRef } = useMopCms();
  const searchResultRef: Ref<
    {
      link: string;
      title: string;
      description: string;
    }[]
  > = ref([]);

  async function searchContent(term: string, limit = 100) {
    await getCmsStoryList('/', {
      starts_with: 'theme-company/',
      search_term: term,
      excluding_slugs: 'theme-company/,theme-company/global',
      per_page: limit,
    });
    searchResultRef.value = cmsStoryListModelRef.value
      .getStoryModelList()
      .reduce((list: { link: string; title: string; description: string }[], story) => {
        const seoData: CmsStorySeoData = story.getSeo();
        const title: string = story.getAttribute('previewTitle') || story.getAttribute('title') || seoData.title;
        const description: string =
          story.getAttribute('previewSubTitle') || story.getAttribute('subTitle') || seoData.description;

        if (title && description) {
          list.push({
            link: escapeStoryblokUrl(story.getFullSlug()),
            title,
            description,
          });
        }
        return list;
      }, []);
  }

  return securedWrap({
    searchContent,
    searchResultRef,
  });
}
