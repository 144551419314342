<script setup lang="ts">
defineOptions({
  name: 'SearchItem',
});

defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['click']);
</script>

<template>
  <div class="search-item">
    <NuxtLink :to="link" no-prefetch @click="emit('click')">
      <h3 class="sarch-item__headline">
        {{ title }}
      </h3>
      <p class="sarch-item__desc">
        {{ description }}
      </p>
      <div class="sarch-item__more">
        {{ $mopI18n.t('locale.read_more') }}
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.sarch-item__headline {
  @include text-style(weighted-bold);

  text-transform: uppercase;
}

.sarch-item__desc {
  @include text-style(paragraph);

  margin: 0;
}

.sarch-item__more {
  @include link-underlined();
  @include text-style(common);
}
</style>
